import './bootstrap.js';

import { createApp } from 'vue';

import VueCountdown from '@chenfengyuan/vue-countdown';

import * as Sentry from '@sentry/vue';

import { _mft, _mftkd } from './i18n.js';
window._mft = _mft;
window._mftkd = _mftkd;

import { _mfHas, _mfHasForget } from './mfHas.js';
window._mfHas = _mfHas;
window._mfHasForget = _mfHasForget;

import { _mfProp, _mfPropForget } from './mfProp.js';
window._mfProp = _mfProp;
window._mfPropForget = _mfPropForget;

import { _mfPrefGet, _mfPrefSet } from './mfPref.js';
window._mfPrefGet = _mfPrefGet;
window._mfPrefSet = _mfPrefSet;

let app = createApp({});

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: import.meta.env.VITE_SENTRY_RELEASE,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.BrowserProfilingIntegration(),
            new Sentry.Replay()
        ],

        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
        tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 0.0,

        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE) || 0.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            "127.0.0.1",
            /^https:\/\/app\.myflow\.se/
        ],

        // Capture Replay for some of all sessions, lus for 100% of sessions with an error
        replaysSessionSampleRate: 0.001,
        replaysOnErrorSampleRate: 1.0,

        // Track vue components
        trackComponents: true,
    });
}


// Adds ziggy route helper
import route from 'ziggy'
app.mixin({ methods: { route } });

// Vue Qr
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
app.component('vue-qr', VueQr);

// SmartTable
import SmartTable from 'vuejs-smart-table';
app.use(SmartTable);

// Vue Signature Pad
import VueSignaturePad from 'vue-signature-pad';
app.use(VueSignaturePad);

// Vue Apexcharts
import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts)
// app.component('apexchart', VueApexCharts)

// Plugins
import PluginDocumentProxy from './plugins/documentProxy.js';
app.use(PluginDocumentProxy);
import PluginPage from './plugins/page.js';
app.use(PluginPage);
import PluginI18n from './plugins/i18n.js';
app.use(PluginI18n);
import PluginSiteRoute from './plugins/siteRoute.js';
app.use(PluginSiteRoute);

// Datastore
import { createPinia } from 'pinia';
app.use(createPinia());

// TRANSLATIONS
import MfTranslate from './components/mfTranslate.vue';
app.component('mf-translate', MfTranslate);
import MfTranslateHtml from './components/mfTranslateHtml.vue';
app.component('mf-translate-html', MfTranslateHtml);


// Auth-Checker
import authChecker from './components/authChecker.vue';
app.component('auth-checker', authChecker)

// Community / Member
import memberCommunities from './components/community/member/memberCommunities.vue';
app.component('member-communities', memberCommunities)

import channel from './components/community/member/channel.vue';
app.component('community-channel', channel)

import _memberships from './components/community/member/_memberships.vue';
app.component('community-memberships-list', _memberships)

import _commentsList from './components/community/member/_commentsList.vue';
app.component('community-channel-comments-list', _commentsList)

import _accountabilityPostData from './components/community/member/_accountabilityPostData.vue';
app.component('accountability-post-data', _accountabilityPostData)

import _accountabilityV2PostData from './components/community/member/_accountabilityV2PostData.vue';
app.component('accountability-v2-post-data', _accountabilityV2PostData)

import _yesterdayReflection from './components/community/member/_yesterdayReflection.vue';
app.component('community-yesterday-reflection', _yesterdayReflection)

import _todaysPostActions from './components/community/member/_todaysPostActions.vue';
app.component('community-todays-post-action', _todaysPostActions)

import communityChannelStatistics from './components/community/statistics/Channel.vue';
app.component('community-channel-statistics', communityChannelStatistics)

// Community / Admin
import listCommunities from './components/community/admin/listCommunities.vue';
app.component('community-admin-list-communities', listCommunities)

import showCommunity from './components/community/admin/showCommunity.vue';
app.component('community-admin-show-community', showCommunity)

import showChannel from './components/community/admin/showChannel.vue';
app.component('community-admin-show-channel', showChannel)


import MfSpinnerSmall from './components/mf/spinner/small.vue';
app.component('mf-spinner-small', MfSpinnerSmall);
import MfSpinnerMedium from './components/mf/spinner/medium.vue';
app.component('mf-spinner-medium', MfSpinnerMedium);
import CountdownCircle from './components/indicators/countdownCircle.vue';
app.component('countdown-circle', CountdownCircle);
import ProgressBar from './components/indicators/progressBar.vue';
app.component('progress-bar', ProgressBar);
import ProgressSteps from './components/indicators/progressSteps.vue';
app.component('progress-steps', ProgressSteps);
import ValidationMark from './components/indicators/validationMark.vue';
app.component('validation-mark', ValidationMark);
import StatusIndicator from './components/indicators/status.vue';
app.component('status-indicator', StatusIndicator);
import KeepScrolling from './components/indicators/keepScrolling.vue';
app.component('keep-scrolling', KeepScrolling);
import RocketLaunch from './components/indicators/rocketLaunch.vue';
app.component('rocket-launch', RocketLaunch);

import MfTable from './components/mf/table.vue';
app.component('mf-table', MfTable);

import AxiosForm from './components/axiosForm.vue';
app.component('axios-form', AxiosForm);

import LinkField from './components/linkField.vue';
app.component('link-field', LinkField);

// Utilities/Helpers
import UtilityIndex from './components/utility/index.vue';
app.component('utility-index', UtilityIndex);

import UtilityPassWordGenerator from './components/utility/passwordGenerator.vue';
app.component('utility-password-generator', UtilityPassWordGenerator);

import UtilityQrGenerator from './components/utility/qrCodeGenerator.vue';
app.component('utility-qr-generator', UtilityQrGenerator);

import UtilityBase64Coder from './components/utility/base64Coder.vue';
app.component('utility-base64-coder', UtilityBase64Coder);

import UtilityUrlShortener from './components/utility/urlShortener.vue';
app.component('utility-url-shortener', UtilityUrlShortener);

import UtilityAddLead from './components/utility/addLead.vue';
app.component('toolbar-add-lead', UtilityAddLead);

import UtilityManageTasks from './components/utility/manageTasks.vue';
app.component('utility-manage-tasks', UtilityManageTasks);

// DATES
import DateTimeBox from './components/datetime/dateTimeBox.vue';
app.component('date-time-box', DateTimeBox)

import LogoSymbol from './components/logo/symbol.vue';
app.component('logo-symbol', LogoSymbol);
import LogoIcon from './components/logo/icon.vue';
app.component('logo-icon', LogoIcon);

import OfferFinder from './components/offerFinder.vue';
app.component('offer-finder', OfferFinder);

// ICONS
import IconChevron from './components/icons/iconChevron.vue';
app.component('icon-chevron', IconChevron);

import VideoRecorder from './components/video-recorder/video-recorder.vue';
app.component('video-recorder', VideoRecorder);
import VideoRecorderRecordAndTrim from './components/video-recorder/recordAndTrim.vue';
app.component('video-recorder-record-and-trim', VideoRecorderRecordAndTrim);

// LOGIN
import LoginForm from './components/auth/loginForm.vue';
app.component('login-form', LoginForm)

// EXPERT REGISTRATION
import ExpertRegistrationForm from './components/expert-registration/form.vue';
app.component('expert-registration-form', ExpertRegistrationForm);

import MyFlowPro from './components/company/myflowPro.vue';
app.component('myflow-pro', MyFlowPro);
import MyFlowProPricing from './components/company/myflowProPricing.vue';
app.component('myflow-pro-pricing', MyFlowProPricing);

// EXPERT PROFILES
import ExpertProfileCreateOrUpdate from './components/expert-profiles/manage/createOrUpdate.vue';
app.component('expert-profile-create-or-update', ExpertProfileCreateOrUpdate);
import ExpertProfileManageOffers from './components/expert-profiles/manage/offers/index.vue';
app.component('expert-profile-manage-offers', ExpertProfileManageOffers);
import ExpertProfileOfferForm from './components/expert-profiles/manage/offers/form.vue';
app.component('expert-profile-offer-form', ExpertProfileOfferForm);
import ExpertSelectGuide from './components/expert-profiles/expertSelectGuide.vue';
app.component('expert-select-guide', ExpertSelectGuide);
import ExpertCard from './components/expert-profiles/expertCard.vue';
app.component('expert-card', ExpertCard);
import ExpertProfileImageWithTrailer from './components/expert-profiles/expertProfileImageWithTrailer.vue';
app.component('expert-profile-image-with-trailer', ExpertProfileImageWithTrailer);
import ExpertProductsPaginatedList from './components/expert-profiles/products/paginatedList.vue';
app.component('expert-products-paginated-list', ExpertProductsPaginatedList);

// Custom Fields
import CrmManageCustomFields from './components/company/crmManageCustomFields.vue';
app.component('crm-manage-custom-fields', CrmManageCustomFields);
import CrmLeadCustomFields from './components/crm/leadCustomFields.vue';
app.component('crm-lead-custom-fields', CrmLeadCustomFields);

// BANKID
import BankidLog from './components/bankid/log.vue';
app.component('bankid-log', BankidLog);
import BankidPrompt from './components/bankid/prompt.vue';
app.component('bankid-prompt', BankidPrompt);

import InsightMeeting from './components/insightMeeting.vue';
app.component('insight-meeting', InsightMeeting);

// EVENT
import EventHeaderBlock from './components/event/header/headerBlock.vue';
app.component('event-header-block', EventHeaderBlock);
import ExpertImageSection from './components/event/expert/expertImage.vue';
app.component('expert-image-section', ExpertImageSection);
import EventPartnersBlock from './components/event/partners/partnersBlock.vue';
app.component('event-partners-block', EventPartnersBlock);
import EventExpertsBlock from './components/event/expert/expertsBlock.vue';
app.component('event-experts-block', EventExpertsBlock);
import EventExpertInfoComponent from './components/event/expert/expertInfo.vue';
app.component('event-expert-info-component', EventExpertInfoComponent);
import EventInformationBlock from './components/event/eventInformationBlock.vue';
app.component('event-information-block', EventInformationBlock);
import MyflowAdvantage from './components/event/myflowAdvantage.vue';
app.component('myflow-advantage', MyflowAdvantage);
import EventStatisticsBlock from './components/event/statistics/statisticsBlock.vue';
app.component('event-statistics-block', EventStatisticsBlock);
import ExpertImageComponent from './components/event/expert/expertImageComponent.vue';
app.component('expert-image-component', ExpertImageComponent);
import PartnersImageComponent from './components/event/partners/partnersImageComponent.vue';
app.component('partners-image-component', PartnersImageComponent);
import EventTextBlockComponent from './components/event/textBlockComponent.vue';
app.component('event-text-block-component', EventTextBlockComponent);
import ChromePluginSelect from './components/chrome-plugin/select.vue';
app.component('chrome-plugin-select', ChromePluginSelect);
import EventFooter from './components/event/eventFooter.vue';
app.component('event-footer', EventFooter);
import EventOnlineLiveInformation from './components/event/liveInformation/eventOnlineLiveInformation.vue';
app.component('event-online-live-information', EventOnlineLiveInformation);
import EventTableComponent from './components/event/liveInformation/eventTableComponent.vue';
app.component('event-table-component', EventTableComponent);
import PartnersVideoComponent from './components/event/partners/partnersVideoComponent.vue';
app.component('partners-video-component', PartnersVideoComponent);
import EventSchemaBlock from './components/event/schema/schemaBlock.vue';
app.component('event-schema-block', EventSchemaBlock);
import SchemaContentComponent from './components/event/schema/schemaContentComponent.vue';
app.component('schema-content-component', SchemaContentComponent);
import EventWorkshop from './components/event/eventWorkshop.vue';
app.component('event-workshop', EventWorkshop);

// PRICING
import ExpertPricing from './components/pricing/expertPricing.vue';
app.component('expert-pricing', ExpertPricing);
import EventOnlineLiveTextComponent from './components/event/liveInformation/eventOnlineLiveTextComponent.vue';
app.component('event-online-live-text-component', EventOnlineLiveTextComponent);
import StatisticsTextComponent from './components/event/statistics/statisticsTextComponent.vue';
app.component('statistics-text-component', StatisticsTextComponent);
import FixedHeader from './components/event/header/fixedHeader.vue';
app.component('fixed-header', FixedHeader);
import EventPricesBlock from './components/event/pricesBlock.vue';
app.component('event-prices-block', EventPricesBlock);
import EventPricesComponent from './components/event/pricesComponent.vue';
app.component('event-prices-component', EventPricesComponent);
import ConfirmMessageBlock from './components/event/confirmation/confirmMessageBlock.vue';
app.component('confirm-message-block', ConfirmMessageBlock);
import MessageDisplay from './components/event/confirmation/messageDisplay.vue';
app.component('message-display', MessageDisplay);
app.component(VueCountdown.name, VueCountdown);

// CONTRACT SIGNATURES
import ContractSignaturesCreate from './components/contract-signatures/create.vue';
app.component('contract-signatures-create', ContractSignaturesCreate);

// EXPERT CONTRACT
import ExpertContractCreate from './components/expert-contracts/create.vue';
app.component('expert-contract-create', ExpertContractCreate);
import ExpertContractEdit from './components/expert-contracts/edit.vue';
app.component('expert-contract-edit', ExpertContractEdit);
import ExpertContractAuditRestore from './components/expert-contracts/auditRestore.vue';
app.component('expert-contract-audit-restore', ExpertContractAuditRestore);
import ExpertContractSignaturesCreate from './components/expert-contract-signatures/create.vue';
app.component('expert-contract-signatures-create', ExpertContractSignaturesCreate);
import ExpertContractSignatureRequestsCreate from './components/expert-contract-signature-requests/create.vue';
app.component('expert-contract-signature-requests-create', ExpertContractSignatureRequestsCreate);

// EXPERT CONTRACTS
import ExpertContractsList from './components/expert-contracts/list.vue';
app.component('expert-contracts-list', ExpertContractsList);
import ClientMessagesSlideout from './components/expert-contracts/clientMessagesSlideOut.vue';
app.component('client-messages-slideout', ClientMessagesSlideout);

// EXPERT CONTRACT SETTINGS
import ExpertContractSettingsSignatureMethods from './components/expert-contract-settings/signatureMethods.vue';
app.component('expert-contract-settings-signature-methods', ExpertContractSettingsSignatureMethods);

import TemplatesList from './components/templates/templatesList.vue';
app.component('templates-list', TemplatesList);
import TemplateInvitations from './components/templates/templateInvitations.vue';
app.component('template-invitations', TemplateInvitations)

// EXPERT CONTRACT TEMPLATES
import ExpertContractsCreateFromExpertContract from './components/expert-contract-templates/createFromExpertContract.vue';
app.component('expert-contracts-create-from-expert-contract', ExpertContractsCreateFromExpertContract);

// CONFIRMATIONS
import UpdateAlert from './components/confirmations/alert.vue';
app.component('update-alert', UpdateAlert);
import WarningAlert from './components/confirmations/warning.vue';
app.component('warning-alert', WarningAlert);
import Notification from './components/confirmations/notification.vue';
app.component('notification', Notification);

// PILLS
import Pill from './components/pills/pillBase.vue';
app.component('pill', Pill);

// BUSINESS PARTNER
import BusinessPartnerSettings from './components/business-partner/settings.vue';
app.component('business-partner-settings', BusinessPartnerSettings);

// BUTTONS
import ButtonBase from './components/buttons/buttonBase.vue';
app.component('button-base', ButtonBase);
import ButtonPrimary from './components/buttons/buttonPrimary.vue';
app.component('button-primary', ButtonPrimary);
import ButtonSecondary from './components/buttons/buttonSecondary.vue';
app.component('button-secondary', ButtonSecondary);
import ButtonMyflow from './components/buttons/buttonMyflow.vue';
app.component('button-myflow', ButtonMyflow);
import ButtonDanger from './components/buttons/buttonDanger.vue';
app.component('button-danger', ButtonDanger);
import ButtonWarning from './components/buttons/buttonWarning.vue';
app.component('button-warning', ButtonWarning);
import ButtonSuccess from './components/buttons/buttonSuccess.vue';
app.component('button-success', ButtonSuccess);
import ButtonTransparent from './components/buttons/buttonTransparent.vue';
app.component('button-transparent', ButtonTransparent);
import ButtonWithConfirmation from './components/buttons/buttonWithConfirmation.vue';
app.component('button-with-confirmation', ButtonWithConfirmation);
import ButtonMoreOptions from './components/buttons/buttonMoreOptions.vue';
app.component('button-more-options', ButtonMoreOptions);
import ButtonMoreOptionsOption from './components/buttons/buttonMoreOptionsOption.vue';
app.component('button-more-options-option', ButtonMoreOptionsOption);
import ButtonMoreOptionsOptionWithConfirmation from './components/buttons/buttonMoreOptionsOptionWithConfirmation.vue';
app.component('button-more-options-option-with-confirmation', ButtonMoreOptionsOptionWithConfirmation);


// CONTENT
import CourseOverview from './components/course/overview.vue';
app.component('course-overview', CourseOverview);
import CourseItemPlayerWithNotes from './components/course/courseItemPlayerWithNotes.vue';
app.component('course-item-player-with-notes', CourseItemPlayerWithNotes);
import ContentVideoPlayer from './components/course/videoPlayer.vue';
app.component('content-video-player', ContentVideoPlayer);
import ContentSelectGuide from './components/course/courseSelectGuide.vue';
app.component('content-select-guide', ContentSelectGuide);
import CourseListitem from './components/content/courseListitem.vue';
app.component('course-listitem', CourseListitem);
import CoachCard from './components/content/coachCard.vue';
app.component('coach-card', CoachCard);
import CoachingProductListitem from './components/content/coachingProductListitem.vue';
app.component('coaching-product-listitem', CoachingProductListitem);
import TrailerVideo from './components/content/trailerVideo.vue';
app.component('trailer-video', TrailerVideo);
import CourseChapterNavigation from './components/course/chapter/navigation.vue';
app.component('course-chapter-navigation', CourseChapterNavigation)
import CourseChapterNavigationModal from './components/course/chapter/navigationModal.vue';
app.component('course-chapter-navigation-modal', CourseChapterNavigationModal)

import CourseChapterCompletionButton from './components/course/chapter/completionButton.vue';
app.component('course-chapter-completion-button', CourseChapterCompletionButton)
import SpecialistListitem from './components/specialist/specialistListItem.vue';
app.component('specialist-listitem', SpecialistListitem);

import PurchaseButtonBase from './components/purchase-button/base.vue';
app.component('purchase-button-base', PurchaseButtonBase);
import ExpertOnboardingSelect from './components/expert-onboarding/selectOrder.vue';
app.component('expert-onboarding-select', ExpertOnboardingSelect);
import ExpertOnboardingAdmin from './components/expert-onboarding/adminOrder.vue';
app.component('expert-onboarding-admin', ExpertOnboardingAdmin);

import CoursePurchaseWithLeadButton from './components/course/purchaseWithLeadButton.vue';
app.component('course-purchase-with-lead-button', CoursePurchaseWithLeadButton);

import EventPurchaseTicketButton from './components/event/purchaseTicketButton.vue';
app.component('event-purchase-ticket-button', EventPurchaseTicketButton);


import UpsideExpert from './components/calculators/upsideExpert.vue';
app.component('upside-expert', UpsideExpert);
import Upside from './components/calculators/upside.vue';
app.component('calculator-upside', Upside);

// TOOLTIPS
import OnhoverFadeupTooltip from './components/tooltips/onhoverFadeupTooltip.vue';
app.component('onhover-fadeup-tooltip', OnhoverFadeupTooltip);
import ContainerWithTooltip from './components/tooltips/containerWithTooltip.vue';
app.component('container-with-tooltip', ContainerWithTooltip);

// FILTERS
import CourseFilterRating from './components/course/filter/rating.vue';
app.component('course-filter-rating', CourseFilterRating);
// GAMES
import WhacAMole from './components/games/whacamole.vue';
app.component('whac-a-mole', WhacAMole);
import ConfettiBomb from './components/games/confetti.vue';
app.component('confetti-bomb', ConfettiBomb);

// COURSE ADMIN
import ManageCourseProviderProfile from './components/course/manage/courseProviderProfile.vue';
app.component('manage-course-provider-profile', ManageCourseProviderProfile);
import CourseForm from './components/course/form.vue';
app.component('course-form', CourseForm);

import CommissionCalculator from './components/calculators/commission.vue';
app.component('commission-calculator', CommissionCalculator);

import CourseChapterSettings from './components/course/chapter/settings.vue';
app.component('course-chapter-settings', CourseChapterSettings);

// LAYOUTS
import AuthLayout from './components/layout/authLayout.vue';
app.component('auth-layout', AuthLayout);
import BackOfficeLayout from './components/layout/backOffice.vue';
app.component('back-office-layout', BackOfficeLayout);

// SHARING
import ShareSlideover from './components/sharing/shareSlideover.vue';
app.component('share-slideover', ShareSlideover);

// PAGE HEADINGS
import BreadcrumbsHeading from './components/layout/pageHeadings/breadcrumbsHeading.vue';
app.component('breadcrumbs-heading', BreadcrumbsHeading);

import FrostedBar from './components/layout/pageHeadings/frostedBar.vue';
app.component('frosted-bar', FrostedBar);

// Widgets
import WidgetSingleValue from './components/widgets/singleValue.vue';
app.component('widget-single-value', WidgetSingleValue);


// Dashboard
import DashboardContractStatus from './components/dashboard/contractStatus.vue';
app.component('dashboard-contract-status', DashboardContractStatus);

// Registration
import RegisterOnboarding from './components/registration/onboarding.vue';
app.component('register-onboarding', RegisterOnboarding);

// Expert Analysis
import ExpertAnalysis from './components/expert-analysis/expertAnalysis.vue';
app.component('expert-analysis', ExpertAnalysis);
import ExpertAnalysisLoginForm from './components/expert-analysis/loginForm.vue';
app.component('expert-analysis-login-form', ExpertAnalysisLoginForm);

import ExpertAnalysisActionableSteps from './components/expert-analysis/actionableSteps.vue';
app.component('expert-analysis-actionable-steps', ExpertAnalysisActionableSteps);

import ExpertAnalysisLeaderboard from './components/expert-analysis/leaderboard.vue';
app.component('expert-analysis-leaderboard', ExpertAnalysisLeaderboard);

// Charts
import ChartRadar from './components/charts/radar.vue';
app.component('chart-radar', ChartRadar);
import ChartPolar from './components/charts/polar.vue';
app.component('chart-polar', ChartPolar);
import ChartRadial from './components/charts/radial.vue';
app.component('chart-radial', ChartRadial);
import ChartRadialOpen from './components/charts/radialOpen.vue';
app.component('chart-radial-open', ChartRadialOpen);
import ChartDonut from './components/charts/donut.vue';
app.component('chart-donut', ChartDonut);
import ChartBar from './components/charts/bar.vue';
app.component('chart-bar', ChartBar);
// Charts (with data url)
import ChartPeriodLine from './components/charts/data-url-driven/periodLine.vue';
app.component('chart-period-line', ChartPeriodLine);
import ChartDistributionBar from './components/charts/data-url-driven/distributionBar.vue';
app.component('chart-distribution-bar', ChartDistributionBar);
import ChartPeriodBar from './components/charts/data-url-driven/periodBar.vue';
app.component('chart-period-bar', ChartPeriodBar);
// BACK OFFICE NAVIGATION
import PrimaryNavigationHeader from './components/navigation/primaryHeader.vue';
app.component('primary-navigation-header', PrimaryNavigationHeader);
import PrimaryNavigationList from './components/navigation/primaryList.vue';
app.component('primary-navigation-list', PrimaryNavigationList);
import PrimaryNavigationItem from './components/navigation/primaryNavigationItem.vue';
app.component('primary-navigation-item', PrimaryNavigationItem);
import SecondaryNavigationBar from './components/navigation/secondaryBar.vue';
app.component('secondary-navigation-bar', SecondaryNavigationBar);
import SecondaryNavigationItem from './components/navigation/secondaryNavigationItem.vue';
app.component('secondary-navigation-item', SecondaryNavigationItem);
import SidebarSubnavigation from './components/navigation/sidebarSubnavigation.vue';
app.component('sidebar-subnavigation', SidebarSubnavigation);
import ImprovementPanel from './components/navigation/improvementPanel.vue';
app.component('improvement-panel', ImprovementPanel);
import ButtonBar from './components/navigation/buttonBar.vue';
app.component('button-bar', ButtonBar);

// CUSTOMER AREA NAVIGATION
import CustomerAreaNavigationBar from './components/customer-area/navigation/navigationBar.vue';
app.component('customer-area-navigation-bar', CustomerAreaNavigationBar);
import CustomerAreaPrimaryNavigationItem from './components/customer-area/navigation/primaryNavigationItem.vue';
app.component('customer-area-primary-navigation-item', CustomerAreaPrimaryNavigationItem);

// SLIDEOUTS
import CallToActionWithSlideout from './components/elements/callToActionWithSlideout.vue';
app.component('call-to-action-with-slideout', CallToActionWithSlideout);
import SlideoutWithSlots from './components/elements/slideoutWithSlots.vue';
app.component('slideout-with-slots', SlideoutWithSlots);
import BasicSlideout from './components/slideouts/basic.vue';
app.component('basic-slideout', BasicSlideout);

import ModalSlideoutWithSlots from './components/elements/modalSlideoutWithSlots.vue';
app.component('modal-slideout-with-slots', ModalSlideoutWithSlots);

// TOOLBARS
import ToolbarBottomRight from './components/toolbars/bottomRight.vue';
app.component('toolbar-bottom-right', ToolbarBottomRight);

// OpenAI
import AiModal from './components/forms/ai/aiModal.vue';
app.component('ai-modal', AiModal);
import AiCoachingProfileDescription from './components/forms/ai/coachingProfileDescription.vue';
app.component('ai-coaching-profile-description', AiCoachingProfileDescription);
import AiIntroduction from './components/forms/ai/aiIntroduction.vue';
app.component('ai-introduction', AiIntroduction);

// LIVESTREAM
import LivestreamCreate from './components/livestream/createLivestream.vue';
app.component('livestream-create', LivestreamCreate);
import LivestreamWherebySession from './components/livestream/wherebyLivestream.vue';
app.component('livestream-whereby-session', LivestreamWherebySession);
import WherebySession from './components/coaching/wherebySession.vue';
app.component('whereby-session', WherebySession);

// Learning Index
import LearningIndexQuestion from './components/learning-index/components/question.vue';
app.component('learning-index-question', LearningIndexQuestion);
import LearningIndexGauge from './components/learning-index/components/gauge.vue';
app.component('learning-index-gauge', LearningIndexGauge);

// General UI
import InfoField from './components/general/infoField.vue';
app.component('info-field', InfoField);

// FORMS
import InputLabel from './components/forms/inputLabel.vue';
app.component('input-label', InputLabel);
import InputError from './components/forms/inputError.vue';
app.component('input-error', InputError);
import InputText from './components/forms/inputText.vue';
app.component('input-text', InputText);
import InputEmail from './components/forms/inputEmail.vue';
app.component('input-email', InputEmail);
import InputNumber from './components/forms/inputNumber.vue';
app.component('input-number', InputNumber);
import InputRange from './components/forms/inputRange.vue';
app.component('input-range', InputRange);
import InputDate from './components/forms/inputDate.vue';
app.component('input-date', InputDate);
import InputTime from './components/forms/inputTime.vue';
app.component('input-time', InputTime);
import InputDatepicker from './components/forms/inputDatepicker.vue';
app.component('input-datepicker', InputDatepicker);
import InputTextarea from './components/forms/inputTextarea.vue';
app.component('input-textarea', InputTextarea);
import InputEditor from './components/forms/inputEditor.vue';
app.component('input-editor', InputEditor);
import InputOption from './components/forms/inputOption.vue';
app.component('input-option', InputOption);
import InputSelect from './components/forms/inputSelect.vue';
app.component('input-select', InputSelect);
import InputSearchable from './components/forms/inputSearchable.vue';
app.component('input-searchable', InputSearchable);
import InputRadios from './components/forms/inputRadios.vue';
app.component('input-radios', InputRadios);
import InputCheckboxes from './components/forms/inputCheckboxes.vue';
app.component('input-checkboxes', InputCheckboxes);
import InputCheckbox from './components/forms/inputCheckbox.vue';
app.component('input-checkbox', InputCheckbox);
import InputBlockEditor from './components/forms/inputBlockEditor.vue';
app.component('input-block-editor', InputBlockEditor);
import InputExpertContractEditor from './components/forms/inputExpertContractEditor.vue';
app.component('input-expert-contract-editor', InputExpertContractEditor);
import InputButton from './components/forms/inputButton.vue';
app.component('input-button', InputButton);
import InputCurrency from './components/forms/inputCurrency.vue';
app.component('input-currency', InputCurrency);
import ToggleButton from './components/forms/toggleButton.vue';
app.component('toggle-button', ToggleButton);
import ToggleDone from './components/forms/toggleDone.vue';
app.component('toggle-done', ToggleDone)
import FormLeadCreate from './components/forms/lead/create.vue';
app.component('form-lead-create', FormLeadCreate);
import ToggleSwitch from './components/forms/toggleSwitch.vue';
app.component('toggle-switch', ToggleSwitch);
import CollaborativeEditor from './components/forms/collaborativeEditor.vue';
app.component('collaborative-editor', CollaborativeEditor);
import ContentEditable from './components/forms/contentEditable.vue';
app.component('content-editable', ContentEditable);
import TogglableInput from './components/forms/togglableInput.vue';
app.component('togglable-input', TogglableInput)
import InputColor from './components/forms/inputColor.vue';
app.component('input-color', InputColor);
import InputMediaBankEntry from './components/forms/inputMediaBankEntry.vue';
app.component('input-media-bank-entry', InputMediaBankEntry);
import InputMediaBankGallery from './components/forms/inputMediaBankGallery.vue';
app.component('input-media-bank-gallery', InputMediaBankGallery);
import InputPexelsSearch from './components/forms/inputPexelsSearch.vue';
app.component('input-pexels-search', InputPexelsSearch);
import FontSelect from './components/forms/fontSelect.vue';
app.component('font-select', FontSelect);
import InputLanguage from './components/forms/inputLanguage.vue';
app.component('input-language', InputLanguage);
import InputTimezone from './components/forms/inputTimezone.vue';
app.component('input-timezone', InputTimezone);

import InputCssMeasure from './components/forms/inputCssMeasure.vue';
app.component('input-css-measure', InputCssMeasure)

import InputColumnLayout from './components/forms/inputColumnLayout.vue';
app.component('input-column-layout', InputColumnLayout)

import InputCssmeasures from './components/forms/inputCssMeasures.vue';
app.component('input-cssmeasures', InputCssmeasures)

import ColorPicker from './components/colorPicker.vue';
app.component('color-picker', ColorPicker);
import ColorPalette from './components/colorPalette.vue';
app.component('color-palette', ColorPalette);


import FormsView from './components/forms/view.vue';
app.component('forms-view', FormsView);

import CopyableText from './components/copyableText.vue';
app.component('copyable-text', CopyableText)

import IconSelectorModal from './components/forms/iconSelector/iconSelectorModal.vue';
app.component('icon-selector-modal', IconSelectorModal);

// Pages
import PageSlugForm from './components/pages/slugForm.vue';
app.component('page-slug-form', PageSlugForm);

import PageCreateForm from './components/pages/createForm.vue';
app.component('page-create-form', PageCreateForm)

import PageSearch from './components/pages/pageSearch.vue';
app.component('page-search', PageSearch);

// Page builder
import PageBuilderEdit from './components/page-builder/pageBuilderEditor.vue';
app.component('page-builder-edit', PageBuilderEdit);
import CssEditor from './components/page-builder/CssEditor.vue';
app.component('css-editor', CssEditor);
import ContractBuilderEdit from './components/page-builder/contractEditor.vue';
app.component('contract-builder-edit', ContractBuilderEdit);
import PageBuilderView from './components/page-builder-view/pageBuilderView.vue';
app.component('page-builder-view', PageBuilderView);

import PageBuilderRow from './components/page-builder-view/row.vue';
app.component('page-builder-row', PageBuilderRow);
import PageBuilderColumn from './components/page-builder-view/column.vue';
app.component('page-builder-column', PageBuilderColumn);
import PageBuilderBlockView from './components/page-builder-view/blockView.vue';
app.component('page-builder-block-view', PageBuilderBlockView);
import PageBuilderBlockEdit from './components/page-builder/block.vue';
app.component('page-builder-block-edit', PageBuilderBlockEdit);

// Page builder templates
import PageBuilderTemplateCreateTemplateModal from './components/page-builder-templates/createTemplateModal.vue';
app.component('page-builder-template-create-template-modal', PageBuilderTemplateCreateTemplateModal);
import PageBuilderTemplateUseTemplateSlideout from './components/page-builder-templates/useTemplateSlideout.vue';
app.component('page-builder-template-use-template-slideout', PageBuilderTemplateUseTemplateSlideout);

// Page builer view blocks
import PageBuilderBlockViewAudio from './components/page-builder-view/block-editor/blocks/audio.vue';
app.component('page-builder-block-view-audio', PageBuilderBlockViewAudio);
import PageBuilderBlockViewCalendly from './components/page-builder-view/block-editor/blocks/calendly.vue';
app.component('page-builder-block-view-calendly', PageBuilderBlockViewCalendly);
import PageBuilderBlockViewIframe from './components/page-builder-view/block-editor/blocks/iframe.vue';
app.component('page-builder-block-view-iframe', PageBuilderBlockViewIframe);
import PageBuilderBlockViewCardTextList from './components/page-builder-view/block-editor/blocks/card-text-list.vue';
app.component('page-builder-block-view-card-text-list', PageBuilderBlockViewCardTextList);
import PageBuilderBlockViewCardText from './components/page-builder-view/block-editor/blocks/card-text.vue';
app.component('page-builder-block-view-card-text', PageBuilderBlockViewCardText);
import PageBuilderBlockViewSpacer from './components/page-builder-view/block-editor/blocks/spacer.vue';
app.component('page-builder-block-view-spacer', PageBuilderBlockViewSpacer);
import PageBuilderBlockViewCronofy from './components/page-builder-view/block-editor/blocks/cronofy.vue';
app.component('page-builder-block-view-cronofy', PageBuilderBlockViewCronofy);
import PageBuilderBlockViewConfetti from './components/page-builder-view/block-editor/blocks/confetti.vue';
app.component('page-builder-block-view-confetti', PageBuilderBlockViewConfetti);
import PageBuilderBlockViewFaq from './components/page-builder-view/block-editor/blocks/faq.vue';
app.component('page-builder-block-view-faq', PageBuilderBlockViewFaq);
import PageBuilderBlockViewFile from './components/page-builder-view/block-editor/blocks/file.vue';
app.component('page-builder-block-view-file', PageBuilderBlockViewFile);
import PageBuilderBlockViewForm from './components/page-builder-view/block-editor/blocks/form.vue';
app.component('page-builder-block-view-form', PageBuilderBlockViewForm);
import PageBuilderBlockViewFormsInputCheckbox from './components/page-builder-view/block-editor/blocks/forms-input-checkbox.vue';
app.component('page-builder-block-view-forms-input-checkbox', PageBuilderBlockViewFormsInputCheckbox);
import PageBuilderBlockViewFormsInputDateTime from './components/page-builder-view/block-editor/blocks/forms-input-date-time.vue';
app.component('page-builder-block-view-forms-input-date-time', PageBuilderBlockViewFormsInputDateTime);
import PageBuilderBlockViewFormsInputDate from './components/page-builder-view/block-editor/blocks/forms-input-date.vue';
app.component('page-builder-block-view-forms-input-date', PageBuilderBlockViewFormsInputDate);
import PageBuilderBlockViewFormsInputEmail from './components/page-builder-view/block-editor/blocks/forms-input-email.vue';
app.component('page-builder-block-view-forms-input-email', PageBuilderBlockViewFormsInputEmail);
import PageBuilderBlockViewFormsInputNumber from './components/page-builder-view/block-editor/blocks/forms-input-number.vue';
app.component('page-builder-block-view-forms-input-number', PageBuilderBlockViewFormsInputNumber);
import PageBuilderBlockViewFormsInputScore from './components/page-builder-view/block-editor/blocks/forms-input-score.vue';
app.component('page-builder-block-view-forms-input-score', PageBuilderBlockViewFormsInputScore);
import PageBuilderBlockViewFormsInputPhone from './components/page-builder-view/block-editor/blocks/forms-input-phone.vue';
app.component('page-builder-block-view-forms-input-phone', PageBuilderBlockViewFormsInputPhone);
import PageBuilderBlockViewFormsInputRadio from './components/page-builder-view/block-editor/blocks/forms-input-radio.vue';
app.component('page-builder-block-view-forms-input-radio', PageBuilderBlockViewFormsInputRadio);
import PageBuilderBlockViewFormsInputSelect from './components/page-builder-view/block-editor/blocks/forms-input-select.vue';
app.component('page-builder-block-view-forms-input-select', PageBuilderBlockViewFormsInputSelect);
import PageBuilderBlockViewFormsInputText from './components/page-builder-view/block-editor/blocks/forms-input-text.vue';
app.component('page-builder-block-view-forms-input-text', PageBuilderBlockViewFormsInputText);
import PageBuilderBlockViewFormsInputTextarea from './components/page-builder-view/block-editor/blocks/forms-input-textarea.vue';
app.component('page-builder-block-view-forms-input-textarea', PageBuilderBlockViewFormsInputTextarea);
import PageBuilderBlockViewFormsInputTime from './components/page-builder-view/block-editor/blocks/forms-input-time.vue';
app.component('page-builder-block-view-forms-input-time', PageBuilderBlockViewFormsInputTime);
import PageBuilderBlockViewFormsInputUpload from './components/page-builder-view/block-editor/blocks/forms-input-upload.vue';
app.component('page-builder-block-view-forms-input-upload', PageBuilderBlockViewFormsInputUpload);
import PageBuilderBlockViewFormsSidebar from './components/page-builder-view/block-editor/blocks/forms-sidebar.vue';
app.component('page-builder-block-view-forms-sidebar', PageBuilderBlockViewFormsSidebar);
import PageBuilderBlockViewFullWidthImage from './components/page-builder-view/block-editor/blocks/full-width-image.vue';
app.component('page-builder-block-view-full-width-image', PageBuilderBlockViewFullWidthImage);
import PageBuilderBlockViewPexels from './components/page-builder-view/block-editor/blocks/pexels.vue';
app.component('page-builder-block-view-pexels', PageBuilderBlockViewPexels);
import PageBuilderBlockViewHeader from './components/page-builder-view/block-editor/blocks/header.vue';
app.component('page-builder-block-view-header', PageBuilderBlockViewHeader);
import PageBuilderBlockViewHeadline from './components/page-builder-view/block-editor/blocks/headline.vue';
app.component('page-builder-block-view-headline', PageBuilderBlockViewHeadline);
import PageBuilderBlockViewImageDescription from './components/page-builder-view/block-editor/blocks/image-description.vue';
app.component('page-builder-block-view-image-description', PageBuilderBlockViewImageDescription);
import PageBuilderBlockViewImageFromUrl from './components/page-builder-view/block-editor/blocks/image-from-url.vue';
app.component('page-builder-block-view-image-from-url', PageBuilderBlockViewImageFromUrl);
import PageBuilderBlockViewImageText from './components/page-builder-view/block-editor/blocks/image-text.vue';
app.component('page-builder-block-view-image-text', PageBuilderBlockViewImageText);
import PageBuilderBlockViewImage from './components/page-builder-view/block-editor/blocks/image.vue';
app.component('page-builder-block-view-image', PageBuilderBlockViewImage);
import PageBuilderBlockViewImageUnfified from './components/page-builder-view/block-editor/blocks/image-unified.vue';
app.component('page-builder-block-view-image-unified', PageBuilderBlockViewImageUnfified);
import PageBuilderBlockViewTaskLists from './components/page-builder-view/block-editor/blocks/task-lists.vue';
app.component('page-builder-block-view-task-lists', PageBuilderBlockViewTaskLists);
import PageBuilderBlockViewLeadCapture from './components/page-builder-view/block-editor/blocks/lead-capture.vue';
app.component('page-builder-block-view-lead-capture', PageBuilderBlockViewLeadCapture);
import PageBuilderBlockViewLogo from './components/page-builder-view/block-editor/blocks/logo.vue';
app.component('page-builder-block-view-logo', PageBuilderBlockViewLogo);
import PageBuilderBlockViewLoomEmbed from './components/page-builder-view/block-editor/blocks/loom-embed.vue';
app.component('page-builder-block-view-loom-embed', PageBuilderBlockViewLoomEmbed);
import PageBuilderBlockViewNavigationButtons from './components/page-builder-view/block-editor/blocks/navigation-buttons.vue';
app.component('page-builder-block-view-navigation-buttons', PageBuilderBlockViewNavigationButtons);
import PageBuilderBlockViewPricingOffer from './components/page-builder-view/block-editor/blocks/pricing-offer.vue';
app.component('page-builder-block-view-pricing-offer', PageBuilderBlockViewPricingOffer);
import PageBuilderBlockViewInvoiceLink from './components/page-builder-view/block-editor/blocks/invoice-link.vue';
app.component('page-builder-block-view-invoice-link', PageBuilderBlockViewInvoiceLink);
import PageBuilderBlockViewQuote from './components/page-builder-view/block-editor/blocks/quote.vue';
app.component('page-builder-block-view-quote', PageBuilderBlockViewQuote);
import PageBuilderBlockViewSingleButton from './components/page-builder-view/block-editor/blocks/single-button.vue';
app.component('page-builder-block-view-single-button', PageBuilderBlockViewSingleButton);
import PageBuilderBlockViewAnchorButton from './components/page-builder-view/block-editor/blocks/anchor-button.vue';
app.component('page-builder-block-view-anchor-button', PageBuilderBlockViewAnchorButton);
import PageBuilderBlockViewSlideshow from './components/page-builder-view/block-editor/blocks/slideshow.vue';
app.component('page-builder-block-view-slideshow', PageBuilderBlockViewSlideshow);
import PageBuilderBlockViewSlimProduct from './components/page-builder-view/block-editor/blocks/slim-product.vue';
app.component('page-builder-block-view-slim-product', PageBuilderBlockViewSlimProduct);
import PageBuilderBlockViewSocialMedia from './components/page-builder-view/block-editor/blocks/social-media.vue';
app.component('page-builder-block-view-social-media', PageBuilderBlockViewSocialMedia);
import PageBuilderBlockViewTextWithTitle from './components/page-builder-view/block-editor/blocks/text-with-title.vue';
app.component('page-builder-block-view-text-with-title', PageBuilderBlockViewTextWithTitle);
import PageBuilderBlockViewText from './components/page-builder-view/block-editor/blocks/text.vue';
app.component('page-builder-block-view-text', PageBuilderBlockViewText);
import PageBuilderBlockViewExternalWidget from './components/page-builder-view/block-editor/blocks/external-widget.vue';
app.component('page-builder-block-view-external-widget', PageBuilderBlockViewExternalWidget);
import PageBuilderBlockViewVideoWithNotes from './components/page-builder-view/block-editor/blocks/video-with-notes.vue';
app.component('page-builder-block-view-video-with-notes', PageBuilderBlockViewVideoWithNotes);
import PageBuilderBlockViewVideo from './components/page-builder-view/block-editor/blocks/video.vue';
app.component('page-builder-block-view-video', PageBuilderBlockViewVideo);
import PageBuilderBlockViewVideoRecorder from './components/page-builder-view/block-editor/blocks/video-recorder.vue';
app.component('page-builder-block-view-video-recorder', PageBuilderBlockViewVideoRecorder);
import PageBuilderBlockViewVimeoLive from './components/page-builder-view/block-editor/blocks/vimeo-live.vue';
app.component('page-builder-block-view-vimeo-live', PageBuilderBlockViewVimeoLive);
import PageBuilderBlockViewVimeo from './components/page-builder-view/block-editor/blocks/vimeo.vue';
app.component('page-builder-block-view-vimeo', PageBuilderBlockViewVimeo);
import PageBuilderBlockViewYoutube from './components/page-builder-view/block-editor/blocks/youtube.vue';
app.component('page-builder-block-view-youtube', PageBuilderBlockViewYoutube);
import PageBuilderBlockViewVotingSuggestions from './components/page-builder-view/block-editor/blocks/voting-suggestions.vue';
app.component('page-builder-block-view-voting-suggestions', PageBuilderBlockViewVotingSuggestions);
import PageBuilderBlockViewCoachingBookings from './components/page-builder-view/block-editor/blocks/coaching-bookings.vue';
app.component('page-builder-block-view-coaching-bookings', PageBuilderBlockViewCoachingBookings);
import PageBuilderBlockViewLicensedCourses from './components/page-builder-view/block-editor/blocks/licensed-courses.vue';
app.component('page-builder-block-view-licensed-courses', PageBuilderBlockViewLicensedCourses);
import PageBuilderBlockViewLoginForm from './components/page-builder-view/block-editor/blocks/login-form.vue';
app.component('page-builder-block-view-login-form', PageBuilderBlockViewLoginForm);
import PageBuilderBlockViewCommunity from './components/page-builder-view/block-editor/blocks/community.vue';
app.component('page-builder-block-view-community', PageBuilderBlockViewCommunity);
import PageBuilderBlockViewFormsInputCrmContactName from './components/page-builder-view/block-editor/blocks/forms-input-crm-contact-name.vue';
app.component('page-builder-block-view-forms-input-crm-contact-name', PageBuilderBlockViewFormsInputCrmContactName);
import PageBuilderBlockViewFormsInputCrmContactEmail from './components/page-builder-view/block-editor/blocks/forms-input-crm-contact-email.vue';
app.component('page-builder-block-view-forms-input-crm-contact-email', PageBuilderBlockViewFormsInputCrmContactEmail);
import PageBuilderBlockViewFormsInputCrmContactPhone from './components/page-builder-view/block-editor/blocks/forms-input-crm-contact-phone.vue';
app.component('page-builder-block-view-forms-input-crm-contact-phone', PageBuilderBlockViewFormsInputCrmContactPhone);
import PageBuilderBlockViewFormsInputCrmContactTitle from './components/page-builder-view/block-editor/blocks/forms-input-crm-contact-title.vue';
app.component('page-builder-block-view-forms-input-crm-contact-title', PageBuilderBlockViewFormsInputCrmContactTitle);
import PageBuilderBlockViewFormsInputCrmContactCompanyName from './components/page-builder-view/block-editor/blocks/forms-input-crm-contact-company-name.vue';
app.component('page-builder-block-view-forms-input-crm-contact-company-name', PageBuilderBlockViewFormsInputCrmContactCompanyName);
import PageBuilderBlockViewFormsInputCrmContactCompanyBillingEmail from './components/page-builder-view/block-editor/blocks/forms-input-crm-contact-company-billing-email.vue';
app.component('page-builder-block-view-forms-input-crm-contact-company-billing-email', PageBuilderBlockViewFormsInputCrmContactCompanyBillingEmail);
import PageBuilderBlockViewFormsInputCrmContactCompanyWebsite from './components/page-builder-view/block-editor/blocks/forms-input-crm-contact-company-website.vue';
app.component('page-builder-block-view-forms-input-crm-contact-company-website', PageBuilderBlockViewFormsInputCrmContactCompanyWebsite);

// App Page builder view blocks
import PageBuilderBlockViewWlAppNavigationButton from './components/page-builder-view/block-editor/blocks/wl-app-navigation-button.vue';
app.component('page-builder-block-view-wl-app-navigation-button', PageBuilderBlockViewWlAppNavigationButton);
import PageBuilderBlockViewWlAppOutlinkButton from './components/page-builder-view/block-editor/blocks/wl-app-outlink-button.vue';
app.component('page-builder-block-view-wl-app-outlink-button', PageBuilderBlockViewWlAppOutlinkButton);
import PageBuilderBlockViewWlAppSpacer from './components/page-builder-view/block-editor/blocks/wl-app-spacer.vue';
app.component('page-builder-block-view-wl-app-spacer', PageBuilderBlockViewWlAppSpacer);

// Page builder view utils
import PageBuilderBlockViewUtilsMediaBankEntry from './components/page-builder-view/block-editor/blocks/util/media-bank-entry.vue';
app.component('page-builder-block-view-utils-media-bank-entry', PageBuilderBlockViewUtilsMediaBankEntry)

// AJAX elements
import AjaxToggleSwitch from './components/forms/ajax/toggleSwitch.vue';
app.component('ajax-toggle-switch', AjaxToggleSwitch);

// BANNERS
import DangerBanner from './components/banners/dangerBanner.vue';
app.component('danger-banner', DangerBanner);
import WarningBanner from './components/banners/warningBanner.vue';
app.component('warning-banner', WarningBanner);
import SuccessBanner from './components/banners/successBanner.vue';
app.component('success-banner', SuccessBanner);

// NOTIFICATIONS
import NotificationIndex from './components/notifications/notificationIndex.vue';
app.component('notification-index', NotificationIndex);
import ListNotification from './components/notifications/notification.vue';
app.component('list-notification', ListNotification)

// FLASH MESSAGES
import FlashMessages from './components/flash-messages/flashMessages.vue';
app.component('flash-messages', FlashMessages);
import FlashMessageConveyer from './components/flash-messages/conveyer.vue';
app.component('flash-message-conveyer', FlashMessageConveyer);

// MESSAGES
import MessageInbox from './components/message/inbox.vue';
app.component('message-inbox', MessageInbox);
import MessageConversation from './components/message/conversation.vue';
app.component('message-conversation', MessageConversation);
import MessageSingle from './components/message/_single.vue';
app.component('message-single', MessageSingle);
import SmsInputCounter from './components/communications/composer/_smsCounter.vue';
app.component('sms-input-counter', SmsInputCounter);

import CommunicationIndex from './components/communications/Index.vue';
app.component('communication-index', CommunicationIndex);

// CONTAINERS
import CardBasic from './components/containers/cardBasic.vue';
app.component('card-basic', CardBasic);
import CardSub from './components/containers/cardSub.vue';
app.component('card-sub', CardSub);
import CardDashed from './components/containers/cardDashed.vue';
app.component('card-dashed', CardDashed);
import CardBranded from './components/containers/cardBranded.vue';
app.component('card-branded', CardBranded);
import CardFormContent from './components/containers/cardFormContent.vue';
app.component('card-form-content', CardFormContent);
import CardCollapsible from './components/containers/cardCollapsible.vue';
app.component('card-collapsible', CardCollapsible);
import ExpandableSection from './components/containers/expandableSection.vue';
app.component('expandable-section', ExpandableSection);
import CardColumnedTwo from './components/containers/cardColumnedTwo.vue';
app.component('card-columned-two', CardColumnedTwo);
import CardTabbed from './components/containers/cardTabbed.vue';
app.component('card-tabbed', CardTabbed);
import CardTabbedTab from './components/containers/_cardTabbedTab.vue';
app.component('card-tabbed-tab', CardTabbedTab);
import UiAttention from './components/containers/uiAttention.vue';
app.component('ui-attention', UiAttention);
import UiTabbed from './components/containers/uiTabbed.vue';
app.component('ui-tabbed', UiTabbed);
import UiTabbedTab from './components/containers/_uiTabbedTab.vue';
app.component('ui-tabbed-tab', UiTabbedTab);

import ListBasic from './components/containers/listBasic.vue';
app.component('list-basic', ListBasic)

import ListBasicItem from './components/containers/listBasicItem.vue';
app.component('list-basic-item', ListBasicItem)

import FullscreenableContainer from './components/elements/fullscreenableContainer.vue';
app.component('fullscreenable-container', FullscreenableContainer);
import CardBook from './components/containers/cardBook.vue';
app.component('card-book', CardBook);
import ListItem from './components/containers/listItem.vue';
app.component('list-item', ListItem);

// CRM
import FilterLeadsIndex from './components/crm/filterLeadsIndex.vue';
app.component('filter-leads-index', FilterLeadsIndex);

import LeadQuickview from './components/crm/leadQuickview.vue';
app.component('lead-quickview', LeadQuickview);

import LeadsMultiManage from './components/crm/leadsMultiManage.vue';
app.component('leads-multi-manage', LeadsMultiManage)


import LeadStakeholderCard from './components/crm/forms/leadStakeholderCard.vue';
app.component('lead-stakeholder-card', LeadStakeholderCard);
import LeadBookingFormCard from './components/crm/leadBookingForm.vue';
app.component('lead-booking-form-card', LeadBookingFormCard);
import LeadActionCard from './components/crm/forms/leadActionCard.vue';
app.component('lead-action-card', LeadActionCard);
import CrmNextActions from './components/crm/nextActions.vue';
app.component('crm-next-actions', CrmNextActions);
import CrmLeadActionItem from './components/crm/leadActionItem.vue';
app.component('crm-lead-action-item', CrmLeadActionItem);
import CrmLeadIndex from './components/crm/leadIndex.vue';
app.component('crm-lead-index', CrmLeadIndex);
import CrmLeadImport from './components/crm/leadImport.vue';
app.component('crm-lead-import', CrmLeadImport);
import LeadReferenceCard from './components/crm/forms/leadReferenceCard.vue';
app.component('lead-reference-card', LeadReferenceCard);
import CrmLeadPage from './components/crm/leadPage.vue';
app.component('crm-lead-page', CrmLeadPage);
import LeadDetails from './components/crm/leadDetails.vue';
app.component('lead-details', LeadDetails);

import SignaturesListCrm from './components/crm/signaturesList.vue';
app.component('signatures-list-crm', SignaturesListCrm);
import DeliveriesListCrm from './components/crm/deliveriesList.vue';
app.component('deliveries-list-crm', DeliveriesListCrm);
import DuplicatesListCrm from './components/crm/duplicatesList.vue';
app.component('duplicates-list-crm', DuplicatesListCrm);
import LeadNoteCard from './components/crm/forms/leadNoteCard.vue';
app.component('lead-note-card', LeadNoteCard);
import LeadDealCard from './components/crm/forms/leadDealCard.vue';
app.component('lead-deal-card', LeadDealCard);
import TagsMultiAssign from './components/crm/tags/multiAssign.vue';
app.component('tags-multi-assign', TagsMultiAssign);
import TagsMultiSelect from './components/crm/tags/multiSelect.vue';
app.component('tags-multi-select', TagsMultiSelect);
import TagsManage from './components/crm/tags/manage.vue';
app.component('tags-manage', TagsManage);
import TagsManageInline from './components/crm/tags/manageInline.vue';
app.component('tags-manage-inline', TagsManageInline);
import OutreachMultiInitiate from './components/crm/outreach/multiInitiate.vue';
app.component('outreach-multi-initiate', OutreachMultiInitiate);
// Crm Communications
import CrmCommunicationsEmailSettingsForm from './components/crm/communications/emailSettingsForm.vue';
app.component('crm-communications-email-settings-form', CrmCommunicationsEmailSettingsForm);

import CrmCommunicationsTagQuery from './components/crm/communications/tagQuery.vue';
app.component('crm-communications-tag-query', CrmCommunicationsTagQuery);

// Communications / Composer
import CommunicationsComposer from './components/communications/composer/composer.vue';
app.component('communications-composer', CommunicationsComposer);
import CommunicationsStats from './components/communications/Stats.vue';
app.component('communications-stats', CommunicationsStats);

// Cloudflare Player
//
import CloudflarePlayer from './components/cloudflare/player.vue';
app.component('cloudflare-player', CloudflarePlayer);

// Video Player
import VideoPlayer from './components/videoplayer/player.vue';
app.component('video-player', VideoPlayer);


// COMPANY
import CompanyUserIndex from './components/company/userIndex.vue';
app.component('company-user-index', CompanyUserIndex);
import FormTeamCreate from './components/forms/teams/createTeam.vue';
app.component('form-team-create', FormTeamCreate);
import AddUserTeam from './components/forms/teams/addToTeams.vue';
app.component('add-user-team', AddUserTeam);

import TeamPlaylistComposer from './components/company/playlists/playlistComposer.vue';
app.component('team-playlist-composer', TeamPlaylistComposer);
import CompanyColorSelector from './components/company/colorPicker.vue';
app.component('company-color-selector', CompanyColorSelector);

// SEARCH
import ContentSearch from './components/search/contentSearch.vue';
app.component('content-search', ContentSearch);

// USER
import ManageUserProfile from './components/forms/user/manageUserProfile.vue';
app.component('manage-user-profile', ManageUserProfile);

// CRONOFY
import CronofyManageIntegration from './components/cronofy/manageIntegration.vue';
app.component('cronofy-manage-integration', CronofyManageIntegration);
import CronofyManageDefaultCalendar from './components/cronofy/manageDefaultCalendar.vue';
app.component('cronofy-manage-default-calendar', CronofyManageDefaultCalendar)
import CronofyManageAvailability from './components/cronofy/manageAvailability.vue';
app.component('cronofy-manage-availability', CronofyManageAvailability);
import CronofyDateTimePicker from './components/cronofy/dateTimePicker.vue';
app.component('cronofy-date-time-picker', CronofyDateTimePicker);
import DummyBlurredCalendar from './components/cronofy/staticBlurredCalendar.vue';
app.component('dummy-blurred-calendar', DummyBlurredCalendar);

import VotingItems from './components/block-editor/components/createVotingItem.vue';
app.component('voting-items', VotingItems);
import EditVotingItems from './components/block-editor/components/editVotingItem.vue';
app.component('edit-voting-items', EditVotingItems);

// LeadHunter
import LeadHunterDetails from './components/lead-hunter/details.vue';
app.component('lead-hunter-details', LeadHunterDetails);

import LeadHunterQuery from './components/lead-hunter/query.vue';
app.component('lead-hunter-query', LeadHunterQuery);

import LeadHunterSearch from './components/lead-hunter/search.vue';
app.component('lead-hunter-search', LeadHunterSearch);

import LeadHunterWorking from './components/lead-hunter/working.vue';
app.component('lead-hunter-working', LeadHunterWorking);

import LeadHunterSummary from './components/lead-hunter/summary.vue';
app.component('lead-hunter-summary', LeadHunterSummary);

import LeadHunterSummaryV2 from './components/lead-hunter/summaryV2.vue';
app.component('lead-hunter-summary-v2', LeadHunterSummaryV2);

import ModulesIndex from './pages/modules/index.vue';
app.component('page-modules-index', ModulesIndex);

import DashboardStats from './components/DashboardStats.vue';
app.component('dashboard-stats', DashboardStats);

// TASK LISTS
import TaskLists from './components/task-list/taskLists.vue';
app.component('task-lists', TaskLists);
import TaskList from './components/task-list/taskList.vue';
app.component('task-list', TaskList);
import Task from './components/task-list/task.vue';
app.component('task', Task);

// COURSES
import LicensedCoursesList from './components/courses/licensedList.vue';
app.component('licensed-courses-list', LicensedCoursesList)

// COACHING
// Coaching v2 components
import CoachingV2ClientSessionsHome from './components/coaching-v2/client/sessions/home.vue';
app.component('coaching-v2-client-sessions-home', CoachingV2ClientSessionsHome)

import CoachingV2ClientSessionsListSession from './components/coaching-v2/client/sessions/list-session.vue';
app.component('coaching-v2-client-sessions-list-session', CoachingV2ClientSessionsListSession)

import CoachingV2ClientSessionsListClient from './components/coaching-v2/client/sessions/list-client.vue';
app.component('coaching-v2-client-sessions-list-client', CoachingV2ClientSessionsListClient)

import CoachingV2SessionsBookingModal from './components/coaching-v2/sessions/_bookingModal.vue';
app.component('coaching-v2-sessions-booking-modal', CoachingV2SessionsBookingModal)

import CoachingV2CoachSessionsHome from './components/coaching-v2/coach/sessions/home.vue';
app.component('coaching-v2-coach-sessions-home', CoachingV2CoachSessionsHome)

// 1.5 components
import CoachingSessionsOverview from './components/coaching/session/overview.vue';
app.component('coaching-sessions-overview', CoachingSessionsOverview);
import CoachingSessionBook from './components/coaching/session/book.vue';
app.component('coaching-session-book', CoachingSessionBook);
import CoachingSessionShow from './components/coaching/session/show.vue';
app.component('coaching-session-show', CoachingSessionShow);
import CoachingSessionsProviderOverview from './components/coaching/session/provider/overview.vue';
app.component('coaching-sessions-provider-overview', CoachingSessionsProviderOverview);
import CoachingSessionProviderShow from './components/coaching/session/provider/show.vue';
app.component('coaching-session-provider-show', CoachingSessionProviderShow);
import CoachingSessionProviderEdit from './components/coaching/session/provider/edit.vue';
app.component('coaching-session-provider-edit', CoachingSessionProviderEdit);

// 1.0 components
import BookingList from './components/coaching/bookingList.vue';
app.component('booking-list', BookingList);
import CoachingCard from './components/coaching/coachingCard.vue';
app.component('coaching-card', CoachingCard);
import CoachSelectGuide from './components/coaching/coachSelectGuide.vue';
app.component('coach-select-guide', CoachSelectGuide);
import CoachProductGuideProduct from './components/coaching/productGuideProduct.vue';
app.component('coach-product-guide-product', CoachProductGuideProduct);
import CoachProductSelectGuide from './components/coaching/productSelectGuide.vue';
app.component('coach-product-select-guide', CoachProductSelectGuide);
import CoachTrailer from './components/coaching/coachTrailer.vue';
app.component('coach-trailer', CoachTrailer);
import CoachingProfileUpdate from './components/forms/coaching/profileUpdate.vue';
app.component('coaching-profile-update', CoachingProfileUpdate);
import ManageCoachingProducts from './components/coaching/manageCoachingProducts.vue';
app.component('manage-coaching-products', ManageCoachingProducts);
import CreateCoachingProduct from './components/forms/coaching/createCoachingProduct.vue';
app.component('create-coaching-product', CreateCoachingProduct);
import EditCoachingProduct from './components/forms/coaching/editCoachingProduct.vue';
app.component('edit-coaching-product', EditCoachingProduct);
import CoachingPastBookings from './components/coaching/pastBookings.vue';
app.component('coaching-past-bookings', CoachingPastBookings);
import CoachingBooking from './components/coaching/booking.vue';
app.component('coaching-booking', CoachingBooking);
import RelatedBooking from './components/coaching/relatedBooking.vue';
app.component('related-booking', RelatedBooking);
import CoachingBookingsPendingApproval from './components/coaching/bookingsPendingApproval.vue';
app.component('coaching-bookings-pending-approval', CoachingBookingsPendingApproval);
import CoachingUpcomingBookings from './components/coaching/upcomingBookings.vue';
app.component('coaching-upcoming-bookings', CoachingUpcomingBookings);
import CalendarWeek from './components/coaching/calendarWeek.vue';
app.component('calendar-week', CalendarWeek);
import CalendarSpanCloneModal from './components/coaching/calendarSpanCloneModal.vue';
app.component('calendar-span-clone-modal', CalendarSpanCloneModal);
import CalendarBooking from './components/coaching/calendarBooking.vue';
app.component('calendar-booking', CalendarBooking);
import BookingCronofyCalendar from './components/coaching/bookingCronofyCalendar.vue';
app.component('booking-cronofy-calendar', BookingCronofyCalendar);
import CoachingPurchaseFreeButton from './components/coaching/purchaseFreeButton.vue';
app.component('coaching-purchase-free-button', CoachingPurchaseFreeButton);
import CoachingPurchaseWithCashSekButton from './components/coaching/purchaseWithCashSekButton.vue';
app.component('coaching-purchase-with-cash-sek-button', CoachingPurchaseWithCashSekButton);
import CoachingPurchaseWithUnclaimedBookingsButton from './components/coaching/purchaseWithUnclaimedBookingsButton.vue';
app.component('coaching-purchase-with-unclaimed-bookings-button', CoachingPurchaseWithUnclaimedBookingsButton);
import CoachingBookingNoteEditor from './components/coaching/booking/note/editor.vue';
app.component('coaching-booking-note-editor', CoachingBookingNoteEditor);
import CoachingBookingNoteViewer from './components/coaching/booking/note/viewer.vue';
app.component('coaching-booking-note-viewer', CoachingBookingNoteViewer);
import CoachingWherebySession from './components/coaching/wherebySession.vue';
app.component('coaching-whereby-session', CoachingWherebySession);
import CoachingSessionCancelButton from './components/coaching/sessionCancelButton.vue';
app.component('coaching-session-cancel-button', CoachingSessionCancelButton);
import CoachingSessionSettings from './components/coaching/session/settings.vue';
app.component('coaching-session-settings', CoachingSessionSettings);

import ShowLivestreamRecordings from './components/livestream/showRecordings.vue';
app.component('show-livestream-recordings', ShowLivestreamRecordings);

import CoachingProfile from './components/coaching/profile/edit.vue';
app.component('coaching-profile', CoachingProfile);

// WHITE LABEL APP MANAGEMENT
import WhitelabelAppDesignEditor from './components/whitelabel-app/design-editor.vue'
app.component('whitelabel-app-design-editor', WhitelabelAppDesignEditor);

// SUBSCRIPTIONS
import SubscribableSelectGuide from './components/subscriptions/subscribableSelectGuide.vue';
app.component('subscribable-select-guide', SubscribableSelectGuide);
import SubscriptionFeed from './components/subscriptions/subscriptionFeed.vue';
app.component('subscription-feed', SubscriptionFeed);
import SubscriptionFeedItem from './components/subscriptions/subscriptionFeedItem.vue';
app.component('subscription-feed-item', SubscriptionFeedItem);
import SubscriptionPurchaseButton from './components/subscriptions/purchaseButton.vue';
app.component('subscription-purchase-button', SubscriptionPurchaseButton);
import SubscribablePaymentTermsPanel from './components/subscriptions/paymentTermsPanel.vue';
app.component('subscribable-payment_terms-panel', SubscribablePaymentTermsPanel);
import SubscribableHistorySettingsPanel from './components/subscriptions/historySettingsPanel.vue';
app.component('subscribable-history-settings-panel', SubscribableHistorySettingsPanel);

// SUBSCRIPTION CONTENTS
import SubscriptionContentPurchaseButton from './components/subscriptions/contents/purchaseButton.vue';
app.component('subscription-content-purchase-button', SubscriptionContentPurchaseButton);
import SubscribableContentPurchaseableSettingsPanel from './components/subscriptions/contents/purchaseableSettingsPanel.vue';
app.component('subscribable-content-purchaseable-settings-panel', SubscribableContentPurchaseableSettingsPanel);

// MODALS
import ModalBasic from './components/modals/basicModal.vue';
app.component('modal-basic', ModalBasic);
import GeneralModal from './components/modals/generalModal.vue';
app.component('general-modal', GeneralModal);
import DangerAlertModal from './components/modals/dangerAlertModal.vue';
app.component('danger-alert-modal', DangerAlertModal);
import FeedbackModal from './components/modals/feedbackModal.vue';
app.component('feedback-modal', FeedbackModal);
import TutorialModal from './components/modal/tutorialModal.vue';
app.component('tutorial-modal', TutorialModal);


// BOTTOM BANNERS
import ExpertProBanner from './components/bottom-ctas/expertPro.vue';
app.component('expert-pro-banner', ExpertProBanner);
import WorkshopRegistrationBanner from './components/bottom-ctas/workshopRegistration.vue';
app.component('workshop-registration-banner', WorkshopRegistrationBanner);

// FILE MANAGEMENT
import FilepondUpload from './components/filePondUpload.vue';
app.component('filepond-upload', FilepondUpload);
import ImageCropper from './components/forms/imageCropper.vue';
app.component('image-cropper', ImageCropper);
import FileUploadWithCropping from './components/fileUploadWithCropping.vue';
app.component('file-upload-with-cropping', FileUploadWithCropping);

// EXTERNAL WIDGETS
import ExternalWidgetPreview from './components/external-widget/preview.vue';
app.component('external-widget-preview', ExternalWidgetPreview);
import ExternalWidget from './components/external-widget/show.vue';
app.component('external-widget', ExternalWidget);

// PAYMENT LINKS
import InvoiceLinkForm from './components/invoice-link/form.vue';
app.component('invoice-link-form', InvoiceLinkForm)

import InvoiceLink from './components/invoice-link/invoiceLink.vue';
app.component('invoice-link', InvoiceLink);

import InvoiceLinkModalInvoiceForm from './components/page-builder-view/block-editor/blocks/components/modal-invoice-form.vue';
app.component('invoice-link-modal-invoice-form', InvoiceLinkModalInvoiceForm);

import InvoiceLinkModalFreeForm from './components/page-builder-view/block-editor/blocks/components/modal-free-form.vue';
app.component('invoice-link-modal-free-form', InvoiceLinkModalFreeForm);

import InvoiceLinkOrderListitem from './components/invoice-link/order/listItem.vue';
app.component('invoice-link-order-listitem', InvoiceLinkOrderListitem);

// CUSTOM PRODUCTS
import CustomProductForm from './components/custom-product/form.vue';
app.component('custom-product-form', CustomProductForm)

// COACHING V2
import CoachingV2OfferableForm from './components/coaching-v2/offerableForm.vue';
app.component('coaching-v2-offerable-form', CoachingV2OfferableForm)

// SUPPORT ARTICLES
import StoryLaneModal from './components/support/storyLaneModal.vue';
app.component('story-lane-modal', StoryLaneModal);

import SupportArticle from './components/support/supportSlideout.vue';
app.component('support-article', SupportArticle);

// Support
import SupportTopBarPanel from './components/support/topBarPanel.vue';
app.component('support-top-bar-panel', SupportTopBarPanel);

// Support chat
import SupportChat from './components/support/chat.vue';
app.component('support-chat', SupportChat);
import SupportChatMessage from './components/support/chatMessage.vue';
app.component('support-chat-message', SupportChatMessage);

// Time Tracking
import TimeTrackingModal from './components/time-tracking/modal.vue';
app.component('time-tracking-modal', TimeTrackingModal);
import TimeTrackingForm from './components/time-tracking/form.vue';
app.component('time-tracking-form', TimeTrackingForm);

// Receipts
import ReceiptsIndex from './components/receipts/index.vue';
app.component('receipts-index', ReceiptsIndex);
import ReceiptsDetails from './components/receipts/details.vue';
app.component('receipts-details', ReceiptsDetails);

// Media Bank
// New uploader
import MediaUpload from './components/mediaBank/upload.vue';
app.component('media-upload', MediaUpload);

import BlackWeek from './components/blackWeek.vue';
app.component('black-week', BlackWeek);
import DashboardSharing from './components/dashboardSharing.vue';
app.component('dashboard-sharing', DashboardSharing);

import MediaBankCloudflareStreamAssetsUploadForm from './components/mediaBank/cloudflareStreamAssets/uploadForm.vue';
app.component('media-bank-cloudflare-stream-assets-upload-form', MediaBankCloudflareStreamAssetsUploadForm);
import MediaBankEntriesUploadForm from './components/mediaBank/entries/uploadForm.vue';
app.component('media-bank-entries-upload-form', MediaBankEntriesUploadForm);
import MediaBankEntriesDisplay from './components/mediaBank/entries/display.vue';
app.component('media-bank-entries-display', MediaBankEntriesDisplay);
import MediaBankEntriesManage from './components/mediaBank/entries/mange.vue';
app.component('media-bank-entries-manage', MediaBankEntriesManage);

// Folders
import FoldersWidget from './components/folders/widget.vue';
app.component('folders-widget', FoldersWidget);

// Dashboard
import DashboardCalculator from './components/dashboard/DashboardCalculator.vue';
app.component('dashboard-calculator', DashboardCalculator);

import AssistantCalculator from './components/dashboard/AssistantCalculator.vue';
app.component('assistant-calculator', AssistantCalculator);
import AssistantLeadHunter from './components/dashboard/AssistantLeadHunter.vue';
app.component('assistant-lead-hunter', AssistantLeadHunter);
import AssistantPrompt from './components/dashboard/AssistantPrompt.vue';
app.component('assistant-prompt', AssistantPrompt);
import AssistantDaily from './components/dashboard/Daily/checkList.vue';
app.component('assistant-daily', AssistantDaily);
import AssistantDailyStats from './components/dashboard/Daily/statsRow.vue';
app.component('assistant-daily-stats', AssistantDailyStats);

// Founding Member
import FoundingMemberOrder from './components/foundingmember/order.vue';
app.component('founding-member-order', FoundingMemberOrder);

// Block editor

import ColorSelector from './components/block-editor/colorSelector.vue';
app.component('color-selector', ColorSelector);
import BlockEditor from './components/block-editor/editor.vue';
app.component('block-editor', BlockEditor);
import BlockEditorLayoutSelector from './components/block-editor/layout-selector.vue';
app.component('block-editor-layout-selector', BlockEditorLayoutSelector);

import BlockEditorFontOptions from './components/block-editor/fontOptions.vue';
app.component('block-editor-font-options', BlockEditorFontOptions);

import BlockEditorDesignOptions from './components/block-editor/designOptions.vue';
app.component('block-editor-design-options', BlockEditorDesignOptions);

import BlockEditorPreviewPanel from './components/block-editor/previewPanel.vue';
app.component('block-editor-preview-panel', BlockEditorPreviewPanel)

import BlockEditorBlocksViewImageText from './components/block-editor/blocks/view/imageText.vue';
app.component('block-editor-blocks-view-image-text', BlockEditorBlocksViewImageText);

import BlockEditorBlocksViewImageDescription from './components/block-editor/blocks/view/imageDescription.vue';
app.component('block-editor-blocks-view-image-description', BlockEditorBlocksViewImageDescription);

import BlockEditorBlocksViewSlideshow from './components/block-editor/blocks/view/slideshow.vue';
app.component('block-editor-blocks-view-slideshow', BlockEditorBlocksViewSlideshow);

import BlockEditorBlocksViewCardText from './components/block-editor/blocks/view/cardText.vue';
app.component('block-editor-blocks-view-card-text', BlockEditorBlocksViewCardText);
import BlockEditorBlocksFaq from './components/block-editor/blocks/view/faq.vue';
app.component('block-editor-blocks-faq', BlockEditorBlocksFaq);

import BlockEditorBlocksViewCardTextList from './components/block-editor/blocks/view/cardTextList.vue';
app.component('block-editor-blocks-view-card-text-list', BlockEditorBlocksViewCardTextList);

import BlockEditorBlocksViewHeader from './components/block-editor/blocks/view/header.vue';
app.component('block-editor-blocks-view-header', BlockEditorBlocksViewHeader);

import BlockEditorBlocksViewFullWidthImage from './components/block-editor/blocks/view/fullWidthImage.vue';
app.component('block-editor-blocks-view-full-width-image', BlockEditorBlocksViewFullWidthImage);

import BlockEditorBlocksViewNavigationButtons from './components/block-editor/blocks/view/navigationButtons.vue';
app.component('block-editor-blocks-view-navigation-buttons', BlockEditorBlocksViewNavigationButtons);

import BlockEditorBlocksViewQuote from './components/block-editor/blocks/view/quote.vue';
app.component('block-editor-blocks-view-quote', BlockEditorBlocksViewQuote);

import BlockEditorBlocksViewTextWithTitle from './components/block-editor/blocks/view/textWithTitle.vue';
app.component('block-editor-blocks-view-text-with-title', BlockEditorBlocksViewTextWithTitle);

import BlockEditorBlocksViewSlimProduct from './components/block-editor/blocks/view/slimProduct.vue';
app.component('block-editor-blocks-view-slim-product', BlockEditorBlocksViewSlimProduct);

import BlockEditorBlocksViewTableOfContents from './components/block-editor/blocks/view/tableOfContents.vue';
app.component('block-editor-blocks-view-table-of-contents', BlockEditorBlocksViewTableOfContents);

import BlockEditorBlocksViewVideoWithNotes from './components/block-editor/blocks/view/videoWithNotes.vue';
app.component('block-editor-blocks-view-video-with-notes', BlockEditorBlocksViewVideoWithNotes);

import BlockEditorBlocksViewCronofy from './components/block-editor/blocks/view/cronofy.vue';
app.component('block-editor-blocks-view-cronofy', BlockEditorBlocksViewCronofy);

import BlockEditorBlocksViewConfetti from './components/block-editor/blocks/view/confetti.vue';
app.component('block-editor-blocks-view-confetti', BlockEditorBlocksViewConfetti);

// Auth Registration

import AuthRegisterFeaturedItemDescription from './components/auth/register/featured-item-description.vue';
app.component('auth-register-featured-item-description', AuthRegisterFeaturedItemDescription);

// Admin
import AdminUserIndex from './components/admin/userIndex.vue';
app.component('admin-user-index', AdminUserIndex);

import AdminSalesProcessIndex from './components/sales-processes/adminIndex.vue';
app.component('admin-sales-process-index', AdminSalesProcessIndex);
import AdminSalesProcessClients from './components/sales-processes/clientIndex.vue';
app.component('admin-sales-process-clients', AdminSalesProcessClients);
import AdminSalesProcessCopyContent from './components/sales-processes/copyContent.vue';
app.component('admin-sales-process-copy-content', AdminSalesProcessCopyContent);
import SalesProcessChooseHelp from './components/sales-processes/chooseHelp.vue';
app.component('sales-process-choose-help', SalesProcessChooseHelp);
import SalesProcessBookingCronofyCalendar from './components/sales-processes/bookingCronofyCalendar.vue';
app.component('sales-process-booking-cronofy-calendar', SalesProcessBookingCronofyCalendar);

import AdminCompanyIndex from './components/admin/companyIndex.vue';
app.component('admin-company-index', AdminCompanyIndex);

import AdminExpertIndex from './components/admin/expertIndex.vue';
app.component('admin-expert-index', AdminExpertIndex);

// Pages
import NotFound from './pages/NotFound.vue';
app.component('page-not-found', NotFound);

import PageBrowserrecordingsIndex from './pages/browser-recordings/Index.vue';
app.component('page-browserrecordings-index', PageBrowserrecordingsIndex);

import PageExpertProfileAvailability from './pages/expert-profile/manage-availability.vue';
app.component('page-expert-profile-availability', PageExpertProfileAvailability);

import PageSpecialistsIndex from './pages/specialists/Index.vue';
app.component('page-specialists-index', PageSpecialistsIndex)

import PageInvoiceLinkIndex from './pages/invoice-link/Index.vue';
app.component('page-invoice-link-index', PageInvoiceLinkIndex)

import PageInvoiceLinkOrderIndex from './pages/invoice-link/order/Index.vue';
app.component('page-invoice-link-order-index', PageInvoiceLinkOrderIndex)

import PagePaymentLinkCreate from './pages/invoice-link/Create.vue';
app.component('page-payment-link-create', PagePaymentLinkCreate)

import PageSettingsInvoicing from './pages/settings/Invoicing.vue';
app.component('page-settings-invoicing', PageSettingsInvoicing);

import PageCustomProductIndex from './pages/custom-product/Index.vue';
app.component('page-custom-product-index', PageCustomProductIndex)

import PageTimeTrackingEntriesIndex from './pages/time-tracking/entries/Index.vue';
app.component('page-time-tracking-entries-index', PageTimeTrackingEntriesIndex);

import PageExternalWidgetIndex from './pages/external-widget/Index.vue';
app.component('page-external-widget-index', PageExternalWidgetIndex)

import PageExternalWidgetEdit from './pages/external-widget/Edit.vue';
app.component('page-external-widget-edit', PageExternalWidgetEdit)

import PageCrmLeadsSingle from './pages/crm/leads/Single.vue';
app.component('page-crm-leads-single', PageCrmLeadsSingle)

import PageLeadHunterHome from './pages/lead-hunter/Home.vue';
app.component('page-lead-hunter-home', PageLeadHunterHome)

import PageLeadHunterSingle from './pages/lead-hunter/Single.vue';
app.component('page-lead-hunter-single', PageLeadHunterSingle)

import PageLeadHunterSingleV2 from './pages/lead-hunter/SingleV2.vue';
app.component('page-lead-hunter-single-v2', PageLeadHunterSingleV2)

import PageCoachingV2OfferableIndex from './pages/coaching-v2/offerable/Index.vue';
app.component('page-coaching-v2-offerable-index', PageCoachingV2OfferableIndex)

import PageCoachingV2ClientSession from './pages/coaching-v2/offerable/session/Client.vue';
app.component('page-coaching-v2-client-session', PageCoachingV2ClientSession)

import PageCoachingV2CoachSession from './pages/coaching-v2/offerable/session/Coach.vue';
app.component('page-coaching-v2-coach-session', PageCoachingV2CoachSession)

import PageCoursesIndex from './pages/courses/Index.vue';
app.component('page-courses-index', PageCoursesIndex)

import PageCoursesCourseEdit from './pages/courses/course/Edit.vue';
app.component('page-courses-course-edit', PageCoursesCourseEdit)

import PageCoursesCourseChapterAdd from './pages/courses/course/chapter/Add.vue';
app.component('page-courses-course-chapter-add', PageCoursesCourseChapterAdd)

import PageCoursesCourseShow from './pages/courses/course/Show.vue';
app.component('page-courses-course-show', PageCoursesCourseShow)

import PageTemplatesIndexPage from './pages/templates/Index.vue';
app.component('page-templates-index-page', PageTemplatesIndexPage)

import PageCommunityAdminIndex from './pages/community/admin/Index.vue';
app.component('page-community-admin-index', PageCommunityAdminIndex)

import PageCommunityAdminModeration from './pages/community/admin/Moderation.vue';
app.component('page-community-admin-moderation', PageCommunityAdminModeration)

import PageCommunityAdminStatisticsIndex from './pages/community/admin/statistics/Index.vue';
app.component('page-community-admin-statistics-index', PageCommunityAdminStatisticsIndex)

import MediaBankIndex from './pages/mediabank/Index.vue';
app.component('page-media-bank-index', MediaBankIndex)

import PagePageIndex from './pages/pages/Index.vue';
app.component('page-page-index', PagePageIndex)

import PageFormEdit from './pages/forms/Edit.vue';
app.component('page-form-edit', PageFormEdit)

import PageFormIndex from './pages/forms/Index.vue';
app.component('page-form-index', PageFormIndex)

import PageFormSubmissionIndex from './pages/forms/submission/Index.vue';
app.component('page-form-submission-index', PageFormSubmissionIndex)

import PageFormStatistics from './pages/forms/statistic/Show.vue';
app.component('page-form-statistic-show', PageFormStatistics)

import PageWeeklyAssistant from './pages/assistants/WeeklyAssistant.vue';
app.component('page-weekly-assistant', PageWeeklyAssistant);

// Whitelabel app
import PageWhitelabelAppManageSettings from './pages/whitelabel-app/settings/Manage.vue';
app.component('page-whitelabel-app-manage-settings', PageWhitelabelAppManageSettings);

// Support article back office
import PageSupportAdminArticleIndex from './pages/support/admin/article/Index.vue';
app.component('page-support-admin-article-index', PageSupportAdminArticleIndex);
import PageSupportAdminArticleEdit from './pages/support/admin/article/Edit.vue';
app.component('page-support-admin-article-edit', PageSupportAdminArticleEdit);


// Support article front office
import PageSupportArticleIndex from './pages/support/article/Index.vue';
app.component('page-support-article-index', PageSupportArticleIndex);

// Support ticket back office
import PageSupportAdminTicketIndex from './pages/support/admin/ticket/Index.vue';
app.component('page-support-admin-ticket-index', PageSupportAdminTicketIndex);
import PageSupportAdminTicketShow from './pages/support/admin/ticket/Show.vue';
app.component('page-support-admin-ticket-show', PageSupportAdminTicketShow);

// Support chat
import PageSupportChatThreadIndex from './pages/support/chat/message-thread/Index.vue';
app.component('page-support-chat-thread-index', PageSupportChatThreadIndex);

import PageSupportChatThreadShow from './pages/support/chat/message-thread/Show.vue';
app.component('page-support-chat-thread-show', PageSupportChatThreadShow);

// Settings
// Navigation settings
import PageSettingsNavigation from './pages/settings/Navigation.vue';
app.component('page-settings-navigation', PageSettingsNavigation);

import SettingsNavigation from './components/settings/Navigation.vue';
app.component('settings-navigation', SettingsNavigation);

// Appearance settings
import PageSettingsAppearance from './pages/settings/Appearance.vue';
app.component('page-settings-appearance', PageSettingsAppearance);

import SettingsAppearance from './components/settings/Appearance.vue';
app.component('settings-appearance', SettingsAppearance);

// Currency settings
import SettingsCurrency from './components/settings/Currency.vue';
app.component('settings-currency', SettingsCurrency);

// Front page settings
import SettingsFrontPage from './components/settings/FrontPage.vue';
app.component('settings-front-page', SettingsFrontPage);
import SettingsCustomUserArea from './components/settings/CustomUserArea.vue';
app.component('settings-custom-user-area', SettingsCustomUserArea);

// Footer settings
import PageSettingsFooter from './pages/settings/Footer.vue';
app.component('page-settings-footer', PageSettingsFooter);
import SettingsFooter from './components/settings/Footer.vue';
app.component('settings-footer', SettingsFooter);

// Policy settings
import PageSettingsPolicy from './pages/settings/Policy.vue';
app.component('page-settings-policy', PageSettingsPolicy);
import SettingsPolicy from './components/settings/Policy.vue';
app.component('settings-policy', SettingsPolicy);
import SettingsAccountCreationEnabled from './components/settings/AccountCreationEnabled.vue';
app.component('settings-account-creation-enabled', SettingsAccountCreationEnabled);

// Domain Validation

import DomainValidationRequests from './components/domain-validation/requests.vue';
app.component('domain-validation-requests', DomainValidationRequests);

// Examples:
// ./components/autoload/on-screen-signature/prompt.vue is imported as <on-screen-signature-prompt>
// ./components/autoload/on-screen-signature/_prompt.vue is not imported
const autoloadComponents = import.meta.glob('./components/autoload/**/*.vue', { eager: true, import: 'default' });
Object.entries(autoloadComponents).forEach(([path, component]) => {
    const segments = path.split('/');
    if (segments.some(segment => segment.startsWith('_'))) { return; }

    const name = path
        .replace(/^\.\/components\/autoload\//, '') // Ta bort prefixet
        .replace(/\.vue$/, '')
        .replace(/\//g, '-');

    app.component(name, component);
});

app.mount('#app');
document.getElementById('app-loading-indicator')?.remove();
