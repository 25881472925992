<template>
    <div class="py-24 sm:py-32">
        <div class="px-6 mx-auto max-w-7xl lg:px-8">
            <div class="max-w-2xl mx-auto sm:text-center">
                <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Maximize Your Potential
                </h2>
                <p class="mt-6 mb-8 text-lg leading-8 text-gray-600">
                    See how our solution can transform your performance.<br>
                    Adjust the sliders below to calculate your potential ROI.
                </p>
            </div>


            <card-basic>
                <div class="px-0 pt-8 pb-2 md:px-8 sm:p-10">
                    <div class="space-y-0 md:space-y-10">
                        <!-- Meetings per Salesperson Input -->
                        <div class="px-4 py-1 transition-all duration-300 rounded-xl md:py-4">
                            <div class="flex items-center justify-between mb-3">
                                <label for="newMeetingsPerMonth"
                                       class="block text-sm font-medium leading-6 text-gray-900 md:text-base">
                                    New meetings each month
                                </label>
                                <span
                                    class="relative px-4 py-2 text-lg font-bold text-center text-white -translate-x-3 rounded-full shadow-md bg-wlPrimary min-w-16 md:translate-x-0"
                                >
                                    <button
                                        class="absolute flex items-center justify-center w-8 h-8 -mr-2 text-black -translate-y-1/2 rounded-full right-full top-1/2 lg:hidden"
                                        @click="updateValue('newMeetingsPerMonth', 'down')"
                                        style="text-shadow: 0 2px 4px rgba(0,0,0,0.25)"
                                    ><i class="fa-solid fa-chevron-left"></i></button>
                                    {{ newMeetingsPerMonth }}
                                    <button
                                        class="absolute flex items-center justify-center w-8 h-8 -ml-2 text-black -translate-y-1/2 rounded-full left-full top-1/2 lg:hidden"
                                        @click="updateValue('newMeetingsPerMonth', 'up')"
                                        style="text-shadow: 0 2px 4px rgba(0,0,0,0.25)"
                                    ><i class="fa-solid fa-chevron-right"></i></button>
                                </span>
                            </div>

                            <div class="relative hidden lg:block">
                                <input id="dealSize"
                                       type="range"
                                       min="5"
                                       max="50"
                                       step="1"
                                       v-model="newMeetingsPerMonth"
                                       class="w-full h-4 border rounded-lg appearance-none cursor-pointer border-myflowPurple-200 bg-gradient-to-r from-myflowPurple-100 to-myflowPurple-300"
                                       style="height: 21px;"
                                />
                                <div class="absolute inset-0 z-10 pointer-events-none">
                                    <div class="relative z-20 mx-3 h-7">
                                        <div
                                            class="absolute z-30 w-6 h-6 -mt-3 -translate-x-1/2 -translate-y-1 bg-white border-2 rounded-full shadow-md pointer-events-none border-wlPrimary"
                                            :style="{ left: `${(Number(newMeetingsPerMonth) - 5) / 45 * 100}%`, top: '50%' }"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-between hidden mt-2 text-xs font-semibold lg:flex text-myflowPurple-600">
                                <span>5</span>
                                <span>50</span>
                            </div>
                        </div>

                        <!-- Average Deal Size Input -->
                        <div class="px-4 py-1 transition-all duration-300 rounded-xl md:py-4">
                            <div class="flex items-center justify-between mb-3">
                                <label for="dealSize" class="block text-sm font-medium leading-6 text-gray-900 md:text-base">
                                    Average Deal Size
                                </label>
                                <span
                                    class="relative px-4 py-2 text-lg font-bold text-center text-white -translate-x-3 rounded-full shadow-md bg-wlPrimary min-w-16 md:translate-x-0"
                                >
                                    <button
                                        class="absolute flex items-center justify-center w-8 h-8 -mr-2 text-black -translate-y-1/2 rounded-full right-full top-1/2 lg:hidden"
                                        @click="updateValue('dealSize', 'down')"
                                        style="text-shadow: 0 2px 4px rgba(0,0,0,0.25)"
                                    ><i class="fa-solid fa-chevron-left"></i></button>
                                    €{{ formatCurrency(dealSize) }}
                                    <button
                                        class="absolute flex items-center justify-center w-8 h-8 -ml-2 text-black -translate-y-1/2 rounded-full left-full top-1/2 lg:hidden"
                                        @click="updateValue('dealSize', 'up')"
                                        style="text-shadow: 0 2px 4px rgba(0,0,0,0.25)"
                                    ><i class="fa-solid fa-chevron-right"></i></button>
                                </span>
                            </div>
                            <div class="relative hidden lg:block">
                                <input id="dealSize"
                                       type="range"
                                       min="1000"
                                       max="100000"
                                       step="1000"
                                       v-model="dealSize"
                                       class="w-full h-4 border rounded-lg appearance-none cursor-pointer border-myflowPurple-200 bg-gradient-to-r from-myflowPurple-100 to-myflowPurple-300"
                                       style="height: 21px;"
                                />
                                <div class="absolute inset-0 z-10 pointer-events-none">
                                    <div class="relative z-20 mx-3 h-7">
                                        <div
                                            class="absolute z-30 w-6 h-6 -mt-3 -translate-x-1/2 -translate-y-1 bg-white border-2 rounded-full shadow-md pointer-events-none border-wlPrimary"
                                            :style="{ left: `${(Number(dealSize) - 1000) / 99000 * 100}%`, top: '50%' }"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-between hidden mt-2 text-xs font-semibold lg:flex text-myflowPurple-600">
                                <span>€1,000</span>
                                <span>€100,000</span>
                            </div>
                        </div>

                        <!-- Conversion Rate Improvement Input -->
                        <div class="px-4 py-1 transition-all duration-300 rounded-xl md:py-4">
                            <div class="flex items-center justify-between mb-3">
                                <label for="meetingConversionRate"
                                       class="block text-sm font-medium leading-6 text-gray-900 md:text-base">
                                    Meeting conversion rate
                                </label>
                                <span
                                    class="relative px-4 py-2 text-lg font-bold text-center text-white -translate-x-3 rounded-full shadow-md bg-wlPrimary min-w-16 md:translate-x-0"
                                >
                                    <button
                                        class="absolute flex items-center justify-center w-8 h-8 -mr-2 text-black -translate-y-1/2 rounded-full right-full top-1/2 lg:hidden"
                                        @click="updateValue('meetingConversionRate', 'down')"
                                        style="text-shadow: 0 2px 4px rgba(0,0,0,0.25)"
                                    ><i class="fa-solid fa-chevron-left"></i></button>
                                    {{ meetingConversionRate }}%
                                    <button
                                        class="absolute flex items-center justify-center w-8 h-8 -ml-2 text-black -translate-y-1/2 rounded-full left-full top-1/2 lg:hidden"
                                        @click="updateValue('meetingConversionRate', 'up')"
                                        style="text-shadow: 0 2px 4px rgba(0,0,0,0.25)"
                                    ><i class="fa-solid fa-chevron-right"></i></button>
                                </span>
                            </div>
                            <div class="relative hidden lg:block">
                                <input id="meetingConversionRate"
                                       type="range"
                                       min="5"
                                       max="100"
                                       v-model="meetingConversionRate"
                                       class="w-full h-4 border rounded-lg appearance-none cursor-pointer border-myflowPurple-200 bg-gradient-to-r from-myflowPurple-100 to-myflowPurple-300"
                                       style="height: 21px;"
                                />
                                <div class="absolute inset-0 z-10 pointer-events-none">
                                    <div class="relative z-20 mx-3 h-7">
                                        <div
                                            class="absolute z-30 w-6 h-6 -mt-3 -translate-x-1/2 -translate-y-1 bg-white border-2 rounded-full shadow-md pointer-events-none border-wlPrimary"
                                            :style="{ left: `${(Number(meetingConversionRate) - 5) / 95 * 100}%`, top: '50%' }"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-between hidden mt-2 text-xs font-semibold lg:flex text-myflowPurple-600">
                                <span>5%</span>
                                <span>100%</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pb-6">
                    <div class="pt-2 text-center md:pt-6 lg:flex-grow lg:flex-col lg:justify-center">
                        <div class="flex items-center justify-center">
                            <div class="flex flex-col items-start gap-y-1">
                                <p class="text-5xl font-bold tracking-tight text-gray-900">
                                    €{{ formatCurrency(additionalAnnualRevenue) }}
                                </p>
                                <p class="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                                    Additional Annual Revenue
                                </p>
                            </div>
                        </div>
                    </div>
                    <p class="text-lg text-center text-gray-400">
                        {{ formatCurrency(returnOfInvestment) }}<span class="pl-px">%</span> Return on Investment
                    </p>
                </div>
            </card-basic>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'

// Input values with reactive references
const productCost = 1490
const newMeetingsPerMonth = ref(10)
const dealSize = ref(1000)
const meetingConversionRate = ref(10)

const updateValue = (valueName, direction) => {
    let value = 1

    if (valueName === 'newMeetingsPerMonth') {
        if (newMeetingsPerMonth.value >= 24) {
            value = 2
        }
        if (direction === 'down') {
            value = 1
            if (newMeetingsPerMonth.value > 24) {
                value = 2
            }
            value = 0 - value
        }
        newMeetingsPerMonth.value = Number(newMeetingsPerMonth.value) + value
    }
    if (valueName === 'dealSize') {
        if (direction === 'up') {
            if (dealSize.value >= 1000) {
                value = 1000
            } else {
                value = 100
            }
        }

        if (direction === 'down') {
            if (dealSize.value >= 2000) {
                value = 1000
            } else {
                value = 100
            }
            value = 0 - value
        }
        dealSize.value = Number(dealSize.value) + value
    }
    if (valueName === 'meetingConversionRate') {
        if (direction === 'down') {
            value = 0 - value
        }
        meetingConversionRate.value = Number(meetingConversionRate.value) + Number(value)
    }
}

// Computed values
const additionalAnnualRevenue = computed(() => {
    return ((Number(newMeetingsPerMonth.value) * (Number(meetingConversionRate.value) / 100)) * 12) * Number(dealSize.value)
})

const returnOfInvestment = computed(() => {
    const revenue = ((Number(newMeetingsPerMonth.value) * (Number(meetingConversionRate.value) / 100)) * 12) * Number(dealSize.value)
    console.log(revenue)

    return Math.round((revenue / productCost) * 100)

})

// Utility functions
const formatCurrency = (value) => {
    return new Intl.NumberFormat('sv-SE').format(value)
}
</script>

<style scoped>
/* Hide the default slider thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
}

input[type="range"]::-moz-range-thumb {
    width: 0;
    height: 0;
    border: 0;
}

/* Override default focus styles */
input[type="range"]:focus {
    outline: none;
}
</style>
